import React from "react";
import { useState } from "react";
import "../css/main.css";
import CollectItem from "../comp/CollectItem";
import ScanerCamera from "../comp/ScanerCameraView";
import Scanner from "../comp/Scanner";
import { useEffect } from "react";
import CameraList from "../comp/CheckCam";
import logo_img from "../imgs/logo.png"
import logo_img_s from "../imgs/logo_s.png"
import footer_img from "../imgs/footer1.png"
import footer_fin_img from "../imgs/footer2.png"
import bgm from "../audio/audio.mp3"
import Sound from 'react-sound';


function AskForCameraPermission() {
  /*
  const askCameraPermission = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    stream.getTracks().forEach(track => track.stop());
  };
	
  askCameraPermission()
  .then(() => console.log('Permission granted'))
  .catch(error => console.log(error));
	
  navigator.mediaDevices.getUserMedia({ video: true })
  .then(function (stream) {	
  })
  .catch(function (err) {			
    alert("無法開啟鏡頭，或不支援的瀏覽器。");
    alert(err);
  });
	
  */
}
function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

const finish_callback = () => {
  return (
    <>
      <div id="finish_overlay_container"></div>
    </>
  );
};


function MainGame() {
  const [is_camera_open, set_camera_open] = useState(false);
  const [is_finish, set_is_finish] = useState(false);
  const [audio_status, set_audio_status] = useState("STOPPED");
  //const [footer_text, set_footer_text] = useState("集章樂");
  const qr_list = [
    {
      qrKey: "ap7541",
      title: "繁花之頂",
      stamp_url: process.env.PUBLIC_URL + "/imgs/s1.png",
      cover_url: process.env.PUBLIC_URL + "/imgs/c1.png",
    },
    {
      qrKey: "sp4587",
      title: "花精靈物語伊娃",
      stamp_url: process.env.PUBLIC_URL + "/imgs/s2.png",
      cover_url: process.env.PUBLIC_URL + "/imgs/c2.png",
    },
    {
      qrKey: "qr1016",
      title: "花精靈物語花之隧道",
      stamp_url: process.env.PUBLIC_URL + "/imgs/s3.png",
      cover_url: process.env.PUBLIC_URL + "/imgs/c3.png",
    },
    {
      qrKey: "pa1575",
      title: "舞台活動區",
      stamp_url: process.env.PUBLIC_URL + "/imgs/s4.png",
      cover_url: process.env.PUBLIC_URL + "/imgs/c4.png",
    },
    {
      qrKey: "zm6715",
      title: "花卉景觀區",
      stamp_url: process.env.PUBLIC_URL + "/imgs/s5.png",
      cover_url: process.env.PUBLIC_URL + "/imgs/c5.png",
    },
    {
      qrKey: "mn1279",
      title: "寵物認養專車",
      stamp_url: process.env.PUBLIC_URL + "/imgs/s6.png",
      cover_url: process.env.PUBLIC_URL + "/imgs/c6.png",
    },
    {
      qrKey: "gg4566",
      title: "消防宣導區",
      stamp_url: process.env.PUBLIC_URL + "/imgs/s7.png",
      cover_url: process.env.PUBLIC_URL + "/imgs/c7.png",
    },
    {
      qrKey: "hf1234",
      title: "美食區工商產業展",
      stamp_url: process.env.PUBLIC_URL + "/imgs/s8.png",
      cover_url: process.env.PUBLIC_URL + "/imgs/c8.png",
    },
    {
      qrKey: "id8941",
      title: "休閒花藝館 舒壓按摩讃",
      stamp_url: process.env.PUBLIC_URL + "/imgs/s9.png",
      cover_url: process.env.PUBLIC_URL + "/imgs/c9.png",
    },
    {
      qrKey: "ja1287",
      title: "彰化優鮮 農產展售區",
      stamp_url: process.env.PUBLIC_URL + "/imgs/s10.png",
      cover_url: process.env.PUBLIC_URL + "/imgs/c10.png",
    },
    /*
     */
  ];

  function OnScanned(result) {
    console.log("Scanned", result);

    let url = new URL(result);
    let params = new URLSearchParams(url.search);
    let myParam = params.get('code');

    let is_game_code = qr_list.some(obj => obj.qrKey === myParam);
    if (is_game_code) {
      // set cookie
      document.cookie = myParam + "=1";
      // Scanned on Clear-qrcode
    }
    if (myParam === "clr876") {
      console.log("complete");
      deleteAllCookies();      
      set_is_finish(false);
    }

    set_camera_open(false)


  }

  useEffect(() => {
    let x = document.cookie;
    console.log("Cookies" + x);

    // Check for finish
    const timer = setInterval(() => {
      let is_finished_all = true;
      qr_list.forEach((element) => {
        is_finished_all =
          is_finished_all && document.cookie.indexOf(element.qrKey) !== -1;
      });

      // If all finish
      if (is_finished_all) {
        clearInterval(timer);
        set_is_finish(true);
      }
    }, 1000); // Check every second

    return () => {
      clearInterval(timer);
    };
  });

  const open_audio = () => {
    if (audio_status === "STOPPED") {
      set_audio_status("PLAYING")
    } 
    /*    
    else if (audio_status === "PLAYING") {
      set_audio_status("STOPPED")
    }
    */
  }

  return (
    <>

      {/** BGM 
    */}
      {audio_status === "PLAYING" &&
        <div>
          <Sound url={bgm} loop={true} playStatus={audio_status} />
        </div>
      }

      {is_camera_open && (
        <div className="top-z main_overlay-container">
          <div className="camera">
            <Scanner callback={OnScanned}></Scanner>
          </div>

          <button
            className="top-z outline main_opt_btn white-blue"
            onClick={() => {
              set_camera_open(!is_camera_open);
              AskForCameraPermission();
            }}
          >
            關閉鏡頭
          </button>
        </div>
      )}

      {/* Black screen */}
      {is_camera_open && <div className="black-panel"></div>}
      {/* top right logo */}
      <img id="logo_small" src={logo_img_s} />

      <div className="main_section" onClick={() => { open_audio() }}>

        <div>
          {/* Leave it blank */}
          <img id="logo_img" src={logo_img} />

        </div>

        {/* Main Table Content */}
        <div className="main_center_layout">

          <div className="main_item-container">
            {qr_list.map((item, index) => (
              <CollectItem
                className="main_item"
                qrKey={item.qrKey}
                title={item.title}
                stamp_url={item.stamp_url}
                cover_url={item.cover_url}
              />
            ))}
          </div>
        </div>
        {/* Footer Camera button */}
        <div className="main_footer_panle">
          {!is_camera_open && (
            <button
              className="outline main_opt_btn white-blue"
              onClick={() => {
                set_camera_open(!is_camera_open);
                AskForCameraPermission();
              }}
            >
              開啟鏡頭
            </button>
          )}
          {!is_finish && (
            <>
              <img className="footer_img" src={footer_img} />
              {/* <span className="big main_footer_text">{"集章樂"}</span>*/}
            </>
          )}
          {is_finish && (
            <>
              <img className="footer_img" src={footer_fin_img} />
              {/* 
              <span className="big main_footer_text">{"集章完成"}</span>
              <span className="small main_footer_text">
                {"請至服務台兌換小禮品"}
              </span>
              */}
            </>
          )}


          {/* 
				<button className='outline main_opt_btn white-blue' onClick={() => {
					set_camera_open(!is_camera_open);
					AskForCameraPermission();
				}}>
					開啟鏡頭
				</button>

				
				<button className='outline main_opt_btn green-white' onClick={() => {
					deleteAllCookies();
					set_is_finish(false);
				}}>
					(測試) 重設
				</button>
				*/}
        </div>

        {/* 
			<div className='main_footer'>
				彰化縣政府xxxx局
			</div>
			*/}
      </div >
    </>
  );
}

export default MainGame;
