import logo from './logo.svg';
import './App.css';
//import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MainGame from "./pages/MainGame"

function App() {
  return (
    <div className="App">
      <MainGame></MainGame>      
    </div>
  );
}

export default App;
