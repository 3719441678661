import React, { useState } from "react";
import { useEffect } from "react";

function CollectItem(props) {

	const [is_collected, set_is_collected] = useState(false);
	let scaned = false;
	useEffect(() => {
		// check for URL
		let url = new URL(window.location.href);
		let params = new URLSearchParams(url.search);
		let myParam = params.get('code');

		console.log("myParam",myParam); 
		if(myParam === props.qrKey && document.cookie.indexOf(props.qrKey) === -1){			
			set_is_collected(true);
			//Remove parameter in the url
			document.cookie = props.qrKey + "=1";
		
			params.delete('code');
			window.history.replaceState({}, '', window.location.pathname);

			//window.location.href = "https://cfflowerstamp.com/";
		}
		else{
			const timer = setInterval(() => {
				scaned = document.cookie.indexOf(props.qrKey) !== -1;			
				set_is_collected(scaned);
				
			}, 1000); // Check every second

			return () => {
				clearInterval(timer);
				set_is_collected(scaned);
			};
		}
	}, [document.cookie])


	const debug_change_item = () => {
		if (!is_collected) {
			set_is_collected(true);
		}
	};

	return (
		<>
			<div className="item">
				<img src={is_collected? props.cover_url: props.stamp_url } alt="img" className="item-thumb" onClick={() => { debug_change_item(); }}/>
				{/* Stamp  Image  
				{is_collected &&
					<div className="blue item-thumb" onClick={() => { debug_change_item(); }}>
					</div>
				}
				{!is_collected && <div className="red item-thumb" onClick={() => { debug_change_item(); }}>
				</div>								
				}
				*/}

				{/* Stamp  Text  
				<span>{props.title}</span>
				*/}
			</div>
		</>
	);

}

export default CollectItem;