import React, { useEffect } from 'react';

function CameraList() {
  useEffect(() => {
    navigator?.mediaDevices?.getUserMedia({ video: { facingMode: 'environment' } }).then(() => {
        console.log('success');
    }).catch((err) => alert('cam error'+err));

    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        const cameras = devices.filter(device => device.kind === 'videoinput');
        console.log(cameras);
        for(var i = 0 ; i<cameras.length ;i++){
            alert(cameras[i].deviceId);

        }
      })
      .catch(error => {
        console.error(error);
        alert(error);
      });
  }, []);

  return (
    <div>
      <p>Check the console for a list of available cameras.</p>
    </div>
  );
}


export default CameraList