import React, { Component } from 'react'
import QrReader from 'modern-react-qr-reader'

export default class Scanner extends Component {
  constructor(props){
    super(props)
    this.state = {
      delay: 500,
      result: 'No result',
    }

    this.handleScan = this.handleScan.bind(this)
    //this.handleError = this.handleError.bind(this)
    //this.handleScan = this.handleScan.bind(this.props.callback)
    console.log(this.props.callback);
  }
  handleScan(data){
    console.log(data)
    this.setState({
      result: data,
    })
    if(data){        
        this.props.callback(data);
    }
  }
  handleError(err){
    alert(err)
    console.error(err)
  }
  render(){
    const previewStyle = {
      height: '100%',
      width: '100%',      
    }

    return(
      <>
        <QrReader
          key="environment"
          delay={this.state.delay}
          style={previewStyle}
          onError={this.handleError}
          onScan={this.handleScan}  
          facingMode ={"environment"}
          constraints={{ facingMode: 'environment' }}        
          />
        <p>{}</p>
      </>
    )
  }
}